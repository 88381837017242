import React, { useContext, useState } from "react";
import { ROUTES, VALIDATION } from "@constants/index";
import { TextField, Alert } from "@components/common";
import { Typography, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { baseApi } from "@utils/apiService";
import { AuthRouterStateType } from "@types";
import { AuthContext } from "@providers/AuthProvider";
import cognitoId from "../../../../../cognitoId";
import * as S from "../../styled";

const SignIn: React.FC = () => {
  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const intl = useIntl();
  const location = useLocation<AuthRouterStateType>();
  const history = useHistory();
  const { setUser } = useContext(AuthContext);

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setIsLoading(true);
    location.state = {};

    cognitoId.authenticateUser(
      email,
      password,
      (result) => {
        const { email: userEmail, name, sub } = result.getIdToken().payload; // TODO check types
        // @ts-expect-error ignore it for now
        const { jwtToken } = result.getIdToken(); // TODO check types
        baseApi.defaults.headers.common.Authorization = jwtToken;
        setUser({
          id: sub,
          email: userEmail,
          name: name || "N/A",
          company_id: result?.getIdToken()?.payload["custom:organization_id"],
          company_ids: result?.getIdToken()?.payload["custom:organization_ids"],
          locale: result.getIdToken().payload.locale || "sk",
          currency: "sk",
          is_admin: result.getIdToken().payload["custom:is_admin"] === "true",
        });
      },
      (err) => {
        setIsLoading(false);
        if (err.code === "UserNotConfirmedException") {
          history.push({
            pathname: ROUTES.USER_VERIFY,
            state: { email, password },
          });
          return;
        }
        if (err.message === "NewPassRequired") {
          history.push({
            pathname: ROUTES.USER_NEW_PASSWORD,
            state: { email, password },
          });
          return;
        }
        setErrorMsg(err?.message);
      }
    );
  };

  return (
    <S.WrapperStyled
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
    >
      <S.LogoStyled />
      <FormProvider {...methods}>
        <S.FormStyled
          onSubmit={handleSubmit(onSubmit)}
          data-test-id='login-form'
        >
          <S.TitleStyled variant='h2'>
            <FormattedMessage id='sign.in.heading' />
          </S.TitleStyled>
          <TextField
            name='email'
            placeholder={intl.formatMessage({
              id: "input.email.placeholder",
            })}
            type='email'
            label={intl.formatMessage({ id: "input.email.label" })}
            error={errors.email}
            pattern={{
              value: VALIDATION.EMAIL_VALIDATION_REGEXP,
              message: intl.formatMessage({ id: "input.email.pattern.error" }),
            }}
            id='form-input-email'
            autoComplete='username'
            offsetbottom='false'
            required
          />
          <TextField
            name='password'
            placeholder={intl.formatMessage({
              id: "input.password.placeholder",
            })}
            type='password'
            label={intl.formatMessage({ id: "input.password.label" })}
            error={errors.password}
            id='form-input-password'
            autoComplete='password'
            offsetbottom='false'
            required
          />
          <S.SubmitButtonStyled
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(onSubmit)}
            type='submit'
            fullWidth
          >
            <FormattedMessage id='sign.in.submit' />
          </S.SubmitButtonStyled>
          {errorMsg && (
            <Alert fullWidth variant='error'>
              {errorMsg}
            </Alert>
          )}

          {location.state?.errorMsg && (
            <Alert variant='error'>{location.state.errorMsg}</Alert>
          )}
          {location.state?.successMsg && (
            <Alert variant='success'>{location.state.successMsg}</Alert>
          )}

          <Grid
            container
            justifyContent='center'
            direction='column'
            alignItems='center'
          >
            <Typography variant='body1' paragraph>
              <S.LinkStyled color='secondary' to={ROUTES.USER_FORGOT_PASSWORD}>
                <FormattedMessage id='sign.in.forgotLink' />
              </S.LinkStyled>
            </Typography>
          </Grid>
        </S.FormStyled>
      </FormProvider>
    </S.WrapperStyled>
  );
};

export default SignIn;
