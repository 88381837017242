import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Typography } from "@material-ui/core";
import { OrderPayload } from "@types";
import CompletedIcon from "@assets/icons/completed.svg";
import { GiftPreview } from "@components/common/Order/Create/Gift/GiftPreview";
import { CreateStep2 } from "@components/common/Order/Create/Gift/Step2";
import {
  TEXTAREA_MAX_LENGTH,
  TEXTAREA_SHORT_MAX_LENGTH,
} from "@constants/common";
import * as S from "../../styled";
import { GiftType } from "../../../../gifts/types";
import { CreateStep3 } from "../Personalization/Step3";

export const Gift: React.FC<{
  companyId: string;
  gifts: GiftType[];
  expanded: boolean;
  disabled: boolean;
  invalid: boolean;
  selectedId?: string;
  handleShowStepContent: (
    stepKey: number,
    show: boolean,
    completed?: boolean
  ) => void;
  setPayload: Dispatch<SetStateAction<OrderPayload>>;
  payload: OrderPayload;
}> = ({
  companyId,
  gifts,
  expanded,
  handleShowStepContent,
  setPayload,
  disabled,
  invalid,
  selectedId,
  payload,
}) => {
  const [inProgress, setInProgress] = useState(expanded);
  const [selectedGift, setSelectedGift] = useState<GiftType>(null);
  const intl = useIntl();
  const exampleTextPersonalized = intl.formatMessage({
    id: "orders.create.step3.giftTextExample",
  });
  const exampleTextGeneral = intl.formatMessage({
    id: "orders.create.step3.giftGeneralTextExample",
  });

  const [noteType, setNoteType] = useState<
    "note_with_text" | "blank_note" | "none"
  >(payload?.gift_note_type || "note_with_text");
  const [giftText, setGiftText] = useState<string | null>(
    payload?.gift_note_text || exampleTextPersonalized
  );

  useEffect(() => {
    if (gifts?.length) {
      setSelectedGift(gifts.find((gift) => gift.id === selectedId));
    }
  }, [gifts, selectedId]);

  useEffect(() => {
    if (!payload) {
      setSelectedGift(null);
    }
  }, [payload]);

  useEffect(() => {
    if (payload?.type && !payload?.gift_note_text) {
      setGiftText(
        payload?.type === "general"
          ? exampleTextGeneral
          : exampleTextPersonalized
      );
    }
  }, [payload?.type]);

  useEffect(() => {
    if (selectedGift?.flags?.has_signature_card) {
      setGiftText("");
    }
  }, [selectedGift?.flags]);

  return (
    <S.CreateCampaignStep invalid={invalid} expanded={expanded}>
      <S.CreateCampaignStepHeader>
        <S.CreateCampaignStepHeaderLeft>
          {selectedGift && payload?.gift_note_type && <CompletedIcon />}
          <div>
            <Typography variant='h4'>
              <FormattedMessage id='orders.create.step2.title' />
            </Typography>
            {selectedGift && !inProgress ? (
              <GiftPreview selectedGift={selectedGift} />
            ) : (
              <Typography color='textSecondary'>
                <FormattedMessage id='orders.create.step2.description' />
              </Typography>
            )}
          </div>
        </S.CreateCampaignStepHeaderLeft>
        {!inProgress && (
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              setInProgress(true);
              handleShowStepContent(1, true);
            }}
            disabled={inProgress || disabled}
          >
            <FormattedMessage id='orders.create.step.edit' />
          </Button>
        )}
      </S.CreateCampaignStepHeader>
      {inProgress && (
        <CreateStep2
          companyId={companyId}
          selectedGift={selectedGift}
          setSelectedGift={setSelectedGift}
        />
      )}
      {inProgress &&
        selectedGift &&
        (selectedGift?.flags.has_handwritten_note ||
          selectedGift?.flags.has_signature_card) && (
          <CreateStep3
            noteType={noteType}
            setNoteType={setNoteType}
            giftText={giftText}
            setGiftText={setGiftText}
            hideDynamicFields={payload?.type === "general"}
            isSignature={selectedGift.flags?.has_signature_card}
          />
        )}
      {inProgress && (
        <div>
          <Button
            variant='contained'
            color='primary'
            disabled={
              (selectedGift && noteType === "note_with_text" && !giftText) ||
              (noteType === "note_with_text" &&
                giftText?.length >
                  (selectedGift?.flags?.has_signature_card
                    ? TEXTAREA_SHORT_MAX_LENGTH
                    : TEXTAREA_MAX_LENGTH))
            }
            onClick={() => {
              setPayload((state) => {
                const oldState = state;
                if (selectedGift) {
                  const newState = {
                    ...state,
                    gift_note_type:
                      !selectedGift?.flags?.has_signature_card &&
                      !selectedGift?.flags?.has_handwritten_note
                        ? "none"
                        : noteType,
                    gift_id: selectedGift?.id,
                    ...(noteType === "note_with_text" && {
                      gift_note_text: giftText,
                    }),
                  };
                  if (noteType !== "note_with_text") {
                    delete newState.gift_note_text;
                  }
                  if (
                    !selectedGift?.flags?.has_signature_card &&
                    !selectedGift?.flags?.has_handwritten_note
                  ) {
                    delete newState.gift_note_text;
                  }
                  return newState;
                }
                delete oldState?.gift_id;
                delete oldState?.gift_note_type;

                return oldState;
              });
              setInProgress(false);
              handleShowStepContent(1, false, false);
            }}
          >
            <FormattedMessage id='general.save' />
          </Button>
          <Button
            color='secondary'
            onClick={() => {
              setInProgress(false);
              handleShowStepContent(1, false);
              setPayload((state) => ({
                ...state,
                gift_note_type: "note_with_text",
                gift_id: null,
                gift_note_text: null,
              }));
              setSelectedGift(null);
            }}
          >
            <FormattedMessage id='general.cancel' />
          </Button>
        </div>
      )}
    </S.CreateCampaignStep>
  );
};
