import currency from "currency.js";

type SummaryCalculationsProps = {
  giftsQty: number;
  giftPrice: number;
  shippingQty: number;
  vat: number;
  freeShipping?: boolean;
};

type SummaryCalculationsResult = {
  subtotal: number;
  calculatedVat: number;
  total: number;
  shippingPrice: number;
  giftTotalPrice: number;
  cardPaymentFee: number;
  totalAfterCardPaymentFee: number;
};

export const SHIPPING_PRICE = 3.75;
export const SHIPPING_VAT = 0.75;
export const CARD_PAYMENT_FEE = 0.25;

const calculateShippingPrice = (shippingQty: number): number => {
  return currency(SHIPPING_PRICE).multiply(shippingQty).value;
};

export const useSummaryCalculations = ({
  giftsQty,
  giftPrice,
  shippingQty,
  vat,
  freeShipping = false,
}: SummaryCalculationsProps): SummaryCalculationsResult => {
  const shippingPrice = freeShipping ? 0 : calculateShippingPrice(shippingQty);
  const giftTotalPrice = currency(giftsQty).multiply(giftPrice);
  const subtotal = currency(giftPrice).multiply(giftsQty).add(shippingPrice);

  const giftsShippingVat = freeShipping
    ? 0
    : currency(shippingQty).multiply(SHIPPING_VAT);

  const calculatedVat = currency(vat).multiply(giftsQty).add(giftsShippingVat);
  const total = subtotal.add(calculatedVat);

  const cardPaymentFee = total.multiply(0.015).add(CARD_PAYMENT_FEE);

  const totalAfterCardPaymentFee = total.add(cardPaymentFee);

  return {
    subtotal: subtotal.value,
    calculatedVat: calculatedVat.value,
    total: total.value,
    shippingPrice: shippingPrice,
    giftTotalPrice: giftTotalPrice.value,
    cardPaymentFee: cardPaymentFee.value,
    totalAfterCardPaymentFee: totalAfterCardPaymentFee.value,
  };
};
