import { Button, Table } from "@components/common";
import {
  Grid,
  TableHead,
  Typography,
  TableRow,
  TableCell,
  TableFooter,
  TableBody,
} from "@material-ui/core";
import { formatMoney } from "@utils/common";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as S from "./styled";
import AlertIcon from "@assets/icons/alert-warning.svg";
import { showModal } from "../../../../../modal/actions";
import { useDispatch } from "react-redux";
import { AuthContext } from "@providers/AuthProvider";
import {
  useSummaryCalculations,
  SHIPPING_PRICE,
} from "../../../../../../hooks/useSummaryCalculations";

type SummaryCalculationsProps = {
  giftsQty: number;
  giftPrice: number;
  shippingQty: number;
  vat: number;
  freeShipping?: boolean;
  doCreditCheck?: boolean;
  paymentMethod: "card" | "credit";
  children: JSX.Element;
};

export const Summary: React.FC<SummaryCalculationsProps> = ({
  giftsQty,
  giftPrice,
  shippingQty,
  vat,
  freeShipping,
  doCreditCheck = true,
  paymentMethod,
  children,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { user } = useContext(AuthContext);

  const {
    subtotal,
    calculatedVat,
    total,
    shippingPrice,
    giftTotalPrice,
    cardPaymentFee,
    totalAfterCardPaymentFee,
  } = useSummaryCalculations({
    giftsQty,
    giftPrice,
    shippingQty,
    vat,
    freeShipping,
  });

  const notEnoughBalance =
    user.credit_balance < total && paymentMethod === "credit";

  return (
    <S.Summary container $hideCreditsInfo={paymentMethod === "card"}>
      <Grid item md={8}>
        <Typography variant='h4' component='h4'>
          <FormattedMessage id='orders.summary.title' />
        </Typography>
        <Typography component='p' color='textSecondary'>
          <FormattedMessage id='orders.summary.subTitle' />
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id='orders.summary.item' />
              </TableCell>
              <TableCell>
                <FormattedMessage id='orders.summary.qty' />
              </TableCell>
              <TableCell>
                <FormattedMessage id='orders.summary.price' />
              </TableCell>
              <TableCell>
                <FormattedMessage id='orders.summary.subtotal' />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <FormattedMessage id='orders.summary.gifts' />
              </TableCell>
              <TableCell>
                <span className='grey'>{giftsQty ? `${giftsQty}x` : "0"}</span>
              </TableCell>
              <TableCell>{formatMoney(giftPrice)}</TableCell>
              <TableCell>
                <b>{formatMoney(giftTotalPrice)}</b>
              </TableCell>
            </TableRow>
            {!freeShipping && (
              <TableRow>
                <TableCell>
                  <FormattedMessage id='orders.summary.shipping' />
                </TableCell>
                <TableCell>
                  <span className='grey'>
                    {shippingQty ? `${shippingQty}x` : "0"}
                  </span>
                </TableCell>
                <TableCell>{formatMoney(SHIPPING_PRICE)}</TableCell>
                <TableCell>
                  <b> {formatMoney(shippingPrice)}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <FormattedMessage id='orders.summary.subtotal' />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <b>{formatMoney(subtotal)}</b>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormattedMessage id='orders.summary.vat' />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <b> {formatMoney(calculatedVat)}</b>
              </TableCell>
            </TableRow>
            {paymentMethod === "card" && (
              <TableRow>
                <TableCell>
                  <FormattedMessage id='orders.summary.fee' />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b> {formatMoney(cardPaymentFee)}</b>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={3}>
                <FormattedMessage
                  id='orders.summary.total'
                  values={{
                    span: (chunks: string[]) => <span>{chunks}</span>,
                  }}
                />
              </TableCell>
              <TableCell>
                <b>
                  {formatMoney(
                    paymentMethod === "card" ? totalAfterCardPaymentFee : total
                  )}
                </b>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      {children}
      {notEnoughBalance && doCreditCheck && (
        <S.AlertStyled fullWidth variant='warning'>
          <AlertIcon />
          <Typography component='h3'>
            <FormattedMessage
              id='general.forms.notEnoughCredits'
              values={{
                button: (chunks: string[]) => (
                  <Button
                    variant='text'
                    color='default'
                    disableRipple
                    onClick={() => {
                      dispatch(
                        showModal({
                          type: "ADD_CREDIT_MODAL",
                          data: {
                            title: intl.formatMessage({
                              id: "credit.modal.title",
                            }),
                            ctaTitle: intl.formatMessage({
                              id: "credit.modal.submit",
                            }),
                          },
                        })
                      );
                    }}
                  >
                    {chunks}
                  </Button>
                ),
              }}
            />
          </Typography>
        </S.AlertStyled>
      )}
    </S.Summary>
  );
};
