import { Grid } from "@material-ui/core";
import { Alert } from "@ui/components";
import styled, { css } from "styled-components";

export const Summary = styled(Grid)<{ $hideCreditsInfo: boolean }>`
  padding: 24px 32px;
  border: 1px solid #ececec;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  width: auto;

  th {
    text-align: left;
    padding: 0;
    color: #a0a0a0;
    font-weight: 400;

    td {
      padding: 6px 0;
    }
  }

  tr {
    border: 0;

    td:first-child {
      min-width: 160px;
    }
  }

  .grey {
    color: #a0a0a0;
  }

  td {
    padding: 0;

    span {
      color: rgb(160, 160, 160);
    }
  }

  ${({ $hideCreditsInfo }) =>
    $hideCreditsInfo &&
    css`
      tfoot td span {
        display: none;
      }
    `}

  tbody {
    tr:last-child {
      td {
        padding-bottom: 20px;
      }
    }
  }

  tfoot {
    tr:first-child {
      border-top: 1px solid #ececec;

      td {
        padding-top: 20px;
      }
    }
    td {
      color: #000;
      font-size: 15px;
    }
  }
`;

export const AlertStyled = styled(Alert)`
  background-color: #ffefef;
  margin-bottom: 0;
  margin-top: 12px;

  > div {
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    gap: 10px;
  }

  svg {
    margin: 0;
  }

  h3 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  button {
    text-decoration: underline;
    height: auto;
    font-weight: bold;
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }
`;
